var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$props.kpis)?_c('Card',{attrs:{"title":_vm.$props.intervention,"value":_vm.$data._.chain(_vm.$props.kpis)
      .map(
        ((_vm.$props.location || 'batch_log_events_interventions_grouped') + "." + (_vm.$props.intervention))
      )
      .map(function (x) { return x || 0; })
      .sum()
      .value(),"values":_vm.$props.kpis
      .map(
        function (x) { return x[_vm.$props.location || 'batch_log_events_interventions_grouped'][
            _vm.$props.intervention
          ] || 0; }
      )
      .reverse(),"labels":_vm.$props.kpis.map(function (x, i) { return (i + 1) * 30 + ' days'; }).reverse()}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }






























import { Component, Vue } from "vue-property-decorator";
import _ from "lodash";
import Card from "@/components/kpi/card.vue";

@Component({
  components: { Card },
  props: ["kpis", "intervention", "location"],
  data: () => ({ _ }),
})
export default class Vessels extends Vue {}

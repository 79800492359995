








import { Component, Vue } from "vue-property-decorator";
import AppEvents from "@/components/kpi/app-events.vue";

@Component({
  components: { AppEvents },
  props: ["kpis", "event"],
})
export default class Vessels extends Vue {}

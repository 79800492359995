



















































































































































































import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import _ from "lodash";
import Card from "@/components/kpi/card.vue";
import AppInterventions from "@/components/kpi/app-interventions.vue";
import InterventionList from "@/components/kpi/app-interventions-list.vue";
import FcInterventions from "@/components/kpi/fc-interventions.vue";
import AppEvents from "@/components/kpi/app-events.vue";
import FcEvents from "@/components/kpi/fc-events.vue";
import moment from "moment";

const duration = moment().diff(moment([2021, 1, 1]), "months", true);
const duration2 = moment().diff(moment([2021, 1, 1]), "quarters", true);
console.warn("duration", duration);
console.warn("duration", duration2);
const months_options = [...Array(Math.ceil(duration)).keys()].map((x) => ({
  begin: +moment("2021-01-01").add(x, "month").toDate(),
  end: +moment("2021-01-01")
    .add(x + 1, "month")
    .toDate(),
  label: moment("2021-01-01").add(x, "months").format("yyyy MMMM"),
}));
const quarter_options = [...Array(Math.ceil(duration2)).keys()].map((x) => ({
  begin: +moment("2021-01-01").add(x, "quarters").toDate(),
  end: +moment("2021-01-01")
    .add(x + 1, "quarters")
    .toDate(),
  label: moment("2021-01-01").add(x, "quarters").format("yyyy Q"),
}));
@Component({
  mounted() {
    (this as any).load();
  },
  components: {
    Card,
    AppInterventions,
    InterventionList,
    FcInterventions,
    AppEvents,
    FcEvents,
  },
  props: ["items"],
  data: () => ({
    kpi: null,
    kpis: [],
    total: {},
    _,
    loading: false,
    brewery: "All",
    months: "All",
    quarter: "All",
    months_options,
    quarter_options,
    breweries: ["All"],
  }),
  methods: {
    async load() {
      this.$data.loading = true;
      try {
        console.warn("selected q:", this.$data.quarter);
        const token = this.$store.state.okta_token;

        const brewery = this.$data.brewery ?? "All";
        const month = this.$data.months;
        const quarter = this.$data.quarter;

        const result2 = await axios.post(
          "https://console.zymoscope.com/api/kpi-background",
          { brewery, month, quarter },
          {
            headers: {
              Authorization: `${token.token_type} ${token.access_token}`,
            },
          }
        );

        const result = await axios.post(
          "https://console.zymoscope.com/api/kpi",
          { brewery, month, quarter },
          {
            headers: {
              Authorization: `${token.token_type} ${token.access_token}`,
            },
          }
        );
        if (brewery == "All") {
          this.$data.breweries = (
            result.data.total[0] ?? { active_breweries: ["All"] }
          ).active_breweries;
        }
        this.$data.kpi = result.data.data[0];
        this.$data.kpis = result.data.data;
        this.$data.total = result.data.total[0] ?? {};
      } catch (error) {
        console.error("Error " + error);
      }
      this.$data.loading = false;
    },
  },
})
export default class Vessels extends Vue {}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{attrs:{"accordion":""}},_vm._l(([
      'CAPPING',
      'DRY_HOPPING',
      'ADDING_FRUIT',
      'ROUSING',
      'PURGING',
      'YEAST_CROPPING' ]),function(item){return _c('v-expansion-panel',{key:item},[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._v(" "+_vm._s(_vm.$data._.chain(_vm.$props.kpis) .map( function (x) { return x["batch_log_events_interventions_grouped"][item] || 0; } ) .sum() .value())+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item)+" ")]),_c('v-expansion-panel-content',[_c('v-sheet',[(_vm.$props)?_c('v-sparkline',{attrs:{"value":_vm.$props.kpis
              .map(
                function (x) { return x['batch_log_events_interventions_grouped'][item] || 0; }
              )
              .reverse(),"labels":_vm.$props.kpis.map(function (x, i) { return (i + 1) * 30 + ' days'; }).reverse(),"color":"purple","height":"100","padding":"24","line-width":"2","stroke-linecap":"round"},scopedSlots:_vm._u([{key:"label",fn:function(item){return [_vm._v(" "+_vm._s(item.value)+" ")]}}],null,true)}):_vm._e()],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$props.kpis)?_c('Card',{attrs:{"title":_vm.$props.event,"value":_vm.$data._.chain(_vm.$props.kpis)
      .map(((_vm.$props.location || 'batch_log_events_grouped') + "." + (_vm.$props.event)))
      .map(function (x) { return x || 0; })
      .sum()
      .value(),"values":_vm.$props.kpis
      .map(
        function (x) { return x[_vm.$props.location || 'batch_log_events_grouped'][_vm.$props.event] || 0; }
      )
      .reverse(),"labels":_vm.$props.kpis.map(function (x, i) { return (i + 1) * 30 + ' days'; }).reverse()},scopedSlots:_vm._u([{key:"after",fn:function(){return [_vm._t("after")]},proxy:true}],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
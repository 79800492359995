








import { Component, Vue } from "vue-property-decorator";
import AppInterventions from "@/components/kpi/app-interventions.vue";

@Component({
  components: { AppInterventions },
  props: ["kpis", "intervention"],
})
export default class Vessels extends Vue {}

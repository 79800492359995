





































import { Component, Vue } from "vue-property-decorator";
import _ from "lodash";

@Component({
  components: {},
  props: ["value", "total", "values", "labels", "title"],
  data: () => ({ _ }),
})
export default class Vessels extends Vue {}















import { Component, Vue } from "vue-property-decorator";
import Login from "@/components/okta-login.vue"; // @ is an alias to /src
import Kpi from "@/components/kpi.vue"; // @ is an alias to /src

@Component({
  components: {
    Login,
    Kpi,
  },
  computed: {},
  data: () => ({
    items: [
      { title: "Calibration", icon: "mdi-view-dashboard" },
      { title: "Breweries", icon: "mdi-factory" },
      { title: "Devices", icon: "mdi-help-box" },
      { title: "Batches", icon: "mdi-glass-mug-variant" },
    ],
    right: null,
  }),
  methods: {},
})
export default class Home extends Vue {}
